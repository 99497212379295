@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Amiri:wght@400;700&display=swap');

@font-face {
  font-family: 'telegrafico';
  src: local('telegrafico'), url('./fonts/telegrafico.ttf') format('truetype');
  font-weight: normal;
}

body {
  margin: 0;
  font-family: 'telegrafico', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
